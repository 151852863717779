import React, { useState } from "react";
import "./NavigationBar.css";
import NavDrawerMenu from "../DrawerMenu/NavDrawerMenu";
import GroupIcon from "@mui/icons-material/DashboardCustomizeOutlined";
import AccountBoxIcon from "../../../assets/AcountBoxWhite.png";
import LogoutIcon from '@mui/icons-material/Logout';
import Layers from "../../../assets/layers.png";
import Coordination from "../../../assets/coordination.png";
import SismoIcon from "../../../assets/earthquake.png";
import Square from "../../../assets/square.png";
import Button from "@mui/material/Button";
import {
  LAYER_MENU,
  GROUP_MENU,
  SECTION_MENU,
  REFRERENCE_SYSTEM_MENU,
  SISMOS_MENU, USERS_MENU,
} from "../../../common/constants";
import { useDrawerMenu, ACTIONS } from "../../../providers/DrawerMenuProvider";
import { useNavigate } from "react-router-dom";
import {Box, ListItemButton, Typography} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const NavigationBar = () => {
  const [drawerMenuContext, setDrawerMenuContext] = useDrawerMenu();
  // const [isOpenDrawerMenu, setIsOpenDrawerMenu] = useState({
  //   LAYER_MENU: false,
  //   SECTION_MENU: false,
  //   REFRERENCE_SYSTEM_MENU: false,
  // });
  const [currentMenu, setCurrentMenu] = useState(false);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const opens = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const cerrarSesion = () => {
    handleClose();
  };

  const toggleDrawer = (value, menu) => {
    setCurrentMenu(menu);
    setDrawerMenuContext({
      action: ACTIONS.SET_OPEN_DRAWER_MENU,
      payload: { menu, value },
    });
    // setIsOpenDrawerMenu({
    //   // ...isOpenDrawerMenu,
    //   [menu]: value
    // })
  };

  const isOpenDrawer = () => {
    return (
      drawerMenuContext.isOpenDrawerMenu[LAYER_MENU] ||
      drawerMenuContext.isOpenDrawerMenu[GROUP_MENU] ||
      drawerMenuContext.isOpenDrawerMenu[SECTION_MENU] ||
      // drawerMenuContext.isOpenDrawerMenu[REFRERENCE_SYSTEM_MENU] ||
      drawerMenuContext.isOpenDrawerMenu[USERS_MENU]
    );
  };

  return (
    <div
      style={{
        width: isOpenDrawer() && 305,
        marginRight: "3rem",
      }}
    >
      <div className="toolbar">
        <div className="toolbar-menu">
          <Button
            onClick={() =>
              toggleDrawer(
                !drawerMenuContext.isOpenDrawerMenu[LAYER_MENU],
                LAYER_MENU
              )
            }
            sx={{
              my: 2,
              color: "white",
              display: "block",
              background: `${
                currentMenu === LAYER_MENU ? "#343434" : "#494949"
              }`,
            }}
            className="nav-button"
          >
            <img src={Layers} alt="capas" />
          </Button>
          <Button
            onClick={() =>
              toggleDrawer(
                !drawerMenuContext.isOpenDrawerMenu[GROUP_MENU],
                GROUP_MENU
              )
            }
            sx={{
              my: 2,
              color: "white",
              display: "block",
              background: `${
                currentMenu === GROUP_MENU ? "#343434" : "#494949"
              }`,
            }}
            className="nav-button"
          >
            {/* <img src={Layers} alt='grupos'/> */}
            <GroupIcon />
          </Button>
          <Button
            onClick={() =>
              toggleDrawer(
                !drawerMenuContext.isOpenDrawerMenu[SECTION_MENU],
                SECTION_MENU
              )
            }
            sx={{
              my: 2,
              color: "white",
              display: "block",
              background: `${
                currentMenu === SECTION_MENU ? "#343434" : "#494949"
              }`,
            }}
            className="nav-button"
          >
            <img src={Square} alt="seccionamiento" />
          </Button>
          {/*<Button*/}
          {/*  onClick={() =>*/}
          {/*    toggleDrawer(*/}
          {/*      !drawerMenuContext.isOpenDrawerMenu[REFRERENCE_SYSTEM_MENU],*/}
          {/*      REFRERENCE_SYSTEM_MENU*/}
          {/*    )*/}
          {/*  }*/}
          {/*  sx={{*/}
          {/*    my: 2,*/}
          {/*    color: "white",*/}
          {/*    display: "block",*/}
          {/*    background: `${*/}
          {/*      currentMenu === REFRERENCE_SYSTEM_MENU ? "#343434" : "#494949"*/}
          {/*    }`,*/}
          {/*  }}*/}
          {/*  className="nav-button"*/}
          {/*>*/}
          {/*  <img src={Coordination} alt="sistemas de referencia" />*/}
          {/*</Button>*/}
          <Button
            onClick={() => {
              if (currentMenu !== SISMOS_MENU) {
                setCurrentMenu(SISMOS_MENU);
                setDrawerMenuContext({
                  action: ACTIONS.CLOSE_ALL_DRAWER_MENU,
                });
                navigate("/sismos");
              }
            }}
            sx={{
              my: 2,
              color: "white",
              display: "block",
              background: `${
                currentMenu === SISMOS_MENU ? "#343434" : "#494949"
              }`,
            }}
            className="nav-button"
          >
            <img src={SismoIcon} alt="sismos" />
          </Button>
          <Button
              onClick={() =>
                  toggleDrawer(
                      !drawerMenuContext.isOpenDrawerMenu[USERS_MENU],
                      USERS_MENU
                  )
              }
              sx={{
                pt: "5px",
                pb: "5px",
                justifyContent: "center",
                alignItems: "center",
                background: `${
                    currentMenu === USERS_MENU ? "#343434" : "#494949"
                }`,
              }}
              className="nav-button button-settings"
          >
            <img src={AccountBoxIcon} alt="" className="boton-img" />
          </Button>
          {/*<Button*/}
          {/*    onClick={handleClick}*/}
          {/*    sx={{*/}
          {/*      */}
          {/*      "&:hover": {*/}
          {/*        backgroundColor: "#313131",*/}
          {/*      },*/}
          {/*    }}*/}
          {/*    */}
          {/*>*/}
          {/*  */}
          {/*</Button>*/}
        </div>
      </div>
      <NavDrawerMenu
        open={isOpenDrawer()}
        toggleDrawer={toggleDrawer}
        currentMenu={currentMenu}
      />
    </div>
  );
};
export default NavigationBar;
