import React, { useContext, useState } from 'react';
import { useMsal } from "@azure/msal-react";
import {
    InteractionRequiredAuthError
} from "@azure/msal-browser";
import axios from '../config/axiosInstance'; // Import the Axios instance
import { loginRequest } from '../authConfig'; // Import the login request

const AuthContext = React.createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [isInterceptorConfigured, setIsInterceptorConfigured] = useState(false);

    const { instance, accounts } = useMsal();

    React.useEffect(() => {
        console.log('AuthProvider')

        const request = {
            scopes: loginRequest.scopes,
            account: accounts.length > 0 ? accounts[0] : null,
        };

        const interceptor = axios.interceptors.request.use(async config => {
            if (request.account) {
                try {
                    const tokenResponse = await instance.acquireTokenSilent(request);
                    config.headers.Authorization = `Bearer ${tokenResponse.accessToken}`;
                } catch (error) {
                    if (error instanceof InteractionRequiredAuthError) {
                        instance.acquireTokenRedirect(request);
                    }
                    console.error(error);
                }
            }
            return config;
        });

        setIsInterceptorConfigured(true);

        return () => {
            axios.interceptors.request.eject(interceptor);
        };
    }, [instance, accounts]);

    const value = {
        axios, // Expose the Axios instance to the components
        isInterceptorConfigured // Expose a flag indicating whether the interceptors have been configured
    };

    return <AuthContext.Provider value={value}>{isInterceptorConfigured && children}</AuthContext.Provider>;
};