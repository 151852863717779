export const geotecniaBaseUrl = process.env.REACT_APP_API_BASE_URL + "/geotecnia";
export const areasBaseUrl = process.env.REACT_APP_API_BASE_URL + "/areas"; //YA
export const sectoresBaseUrl = process.env.REACT_APP_API_BASE_URL + "/sectores";
export const acelerometrosBaseUrl = process.env.REACT_APP_API_BASE_URL + "/acelerometros";
export const insarBaseUrl = process.env.REACT_APP_API_BASE_URL + "/insar"; //YA
export const ortofotoBaseUrl = process.env.REACT_APP_API_BASE_URL + "/ortofotos"; //YA
export const curvasNivelBaseUrl = process.env.REACT_APP_API_BASE_URL + "/curvas-nivel"; //YA
export const mapasBaseUrl = process.env.REACT_APP_API_BASE_URL + "/mapas-elevacion"; //YA
export const dtmBaseUrl = process.env.REACT_APP_API_BASE_URL + "/modelos-terreno-digital"; //YA
export const ejesMuroBaseUrl = process.env.REACT_APP_API_BASE_URL + "/ejes-muro"; //YA
export const auxiliaresBaseUrl = process.env.REACT_APP_API_BASE_URL + "/auxiliares"; //YA
export const grietasBaseUrl = process.env.REACT_APP_API_BASE_URL + "/grietas";  //YA
export const puntosDescargaBaseUrl = process.env.REACT_APP_API_BASE_URL + "/puntos-descarga"; //YA
export const revanchasBaseUrl = process.env.REACT_APP_API_BASE_URL + "/revanchas"; //YA
export const sismosBaseUrl = process.env.REACT_APP_API_BASE_URL + "/sismos";
export const planesCargaBaseUrl = process.env.REACT_APP_API_BASE_URL + "/planes-carga"; //YA
export const batimetriasUrl = process.env.REACT_APP_API_BASE_URL + "/batimetrias";  //YA
export const estacionesMetBaseUrl = process.env.REACT_APP_API_BASE_URL + "/estaciones-meteorologicas";  //YA
// export const planesCargaBaseUrl = "http://localhost:59953"
// export const revanchasBaseUrl = "https://localhost:5001/"
